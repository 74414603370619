import { NewClientComponent } from './pages/clients/new-client/new-client.component';
import { ClientsComponent } from './pages/clients/clients/clients.component';
import { AssemblyComponent } from './pages/manufacture/assembly/assembly.component';
import { CreateComponent } from './pages/orders/subpages/create/create.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { LogoutComponent } from './pages/auth/logout/logout.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { OrderComponent } from './pages/order/order.component';

const routes: Routes = [
  { path: 'signin', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [MsalGuard]},

  { path: 'orders', component: OrdersComponent, canActivate: [MsalGuard] },
  { path: 'orders/new', component: CreateComponent, canActivate: [MsalGuard]},
  { path: 'orders/:oid', component: OrderComponent, canActivate: [MsalGuard] },

  /**
   * Manufacture
   */
  { path: 'manufacture/assembly/:oid', component: AssemblyComponent, canActivate: [MsalGuard] },

  // Clients
  { path: 'clients', component: ClientsComponent, canActivate: [MsalGuard] },
  { path: 'clients/new', component: NewClientComponent, canActivate: [MsalGuard]},

  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
