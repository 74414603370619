import { OrderService } from './../../services/orders/order.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {


  public orders: any;

  constructor(private orderService: OrderService) { }

  ngOnInit() {
    this.orderService.getAllOrders()
      .subscribe(response => {
        this.orders = response.data;
        console.log(this.orders);
      });

  }

}
