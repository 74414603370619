import { MsalService } from '@azure/msal-angular';
import { UserService } from './../../../services/user/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  profile: any;
  constructor(private userService: UserService, private authService: MsalService) { }

  ngOnInit() {
    this.userService.getProfile((profile) => {
      this.profile = profile;
    });

  }

  logout() {
    this.authService.logout();
  }

}
