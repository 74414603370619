import { ClientService } from './../../../../services/clients/client.service';
import { Order } from './../../../../models/order';
import { OrderService } from './../../../../services/orders/order.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  form = this.fb.group({
    client: [''],
    name: [''],
    deliveryDate: ['']
  });

  clients: Client[];

  constructor(private orderService: OrderService, private fb: FormBuilder, private router: Router, private clientService: ClientService) { }

  ngOnInit() {
    this.getClients();
  }

  submit() {
    const order = this.formToOrder();
    console.log(order);
    this.orderService.createOrder(order, (result) => {
      console.log(result);
      this.router.navigate(['orders/' + result.newOrder._id]);
    }, () => {
      this.form.reset();
    });

  }

  formToOrder() {
    const newOrder = {
      name: this.form.get('name').value,
      client: this.form.get('client').value,
      deliveryDate: this.form.get('deliveryDate').value
    };
    return newOrder;
  }

  getClients() {
    this.clientService.getAll()
      .subscribe(result => {
        this.clients = result.data;
      });
  }

}
