import { ClientService } from './../../../services/clients/client.service';
import { Client } from './../../../models/client';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent implements OnInit {

  form = this.fb.group({
    name: [''],
    email: [''],
  });

  constructor(private fb: FormBuilder, private clientService: ClientService, private router: Router) { }

  ngOnInit() {
  }

  submit() {
    const client: Client = {
      name: this.form.get('name').value,
      email: this.form.get('email').value
    };

    this.clientService.createClient(client,
      () => {
        this.router.navigate(['/clients']);
      },
      () => {
        this.form.reset();
      });

  }

}
