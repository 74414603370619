import { Order } from './../../models/order';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config/global';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }


  /**
   * Gets all orders
   */
  getAllOrders() {
    return this.http.get<{ data: any}>(Config.api.getBaseUrl() + '/orders/getAllUnfinished');
  }

  /**
   * Gets order
   * @param id
   */
  getOrder(id: string) {
    return this.http.get< {data: any}>(Config.api.getBaseUrl() + '/orders/' + id);
  }

  /**
   * Creates order
   * @param order
   */
  createOrder(order: Order, success: (res) => any, fail: (err) => any) {
    console.log(order);
    this.http.post<{data: any}>(Config.api.getBaseUrl() + '/orders', {order})
      .subscribe(result => {
        return success(result);
      },
      err => {
        return fail(err);
      });
  }

  /**
   * Adds bom to order
   * @param oid
   * @param bom
   * @param success
   * @param fail
   */
  addBOMToOrder(oid: string, bom: File, success: (res) => any, fail: (err) => any) {
    const formData: FormData = new FormData();

    formData.append('file', bom);
    formData.append('orderId', oid);

    this.http.post<{data: any}>(Config.api.getBaseUrl() + '/bom', formData)
      .subscribe(result => {
        return success(result);
      },
      err => {
        return fail(err);
      });
  }

  getOptimizedCuttingListDiagram(bomid: string, outputType: string) {
    this.http.post(Config.api.getBaseUrl() + '/optimizer/' + bomid + '/' + outputType, {}, { responseType: 'blob' })
      .subscribe(result => {
        FileSaver.saveAs(result, bomid + '.pdf');
      });
  }

  delete(id: string, success: (res) => any, fail: (err) => any) {
    this.http.delete<{data: any}>(Config.api.getBaseUrl() + '/orders/' + id)
      .subscribe(result => {
        return success(result);
      },
      err => {
        return fail(err);
      });
  }

  complete(id: string, success: (res) => any, fail: (err) => any) {
    this.http.get<{ data: any}>(Config.api.getBaseUrl() + '/orders/' + id + '/complete')
      .subscribe(res => {
        return success(res);
      }, err => {
        return fail(err);
      });
  }
}
