import { OrderService } from './../../services/orders/order.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';

export interface Cabinet {
  _id: string;
  unitCode: string;
}

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  id: string;
  order: any;
  cabinets: Cabinet[];
  private sub: any;
  columnsToDisplay = ['unitCode', 'complete'];


  constructor(private orderService: OrderService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.getOrder();
  }



  /**
   * Gets order
   */
  getOrder() {
    // get id param
    this.sub = this.route.params.subscribe(params => {
      this.id = params.oid; // (+) converts string 'id' to a number

      this.orderService.getOrder(this.id)
        .subscribe(result => {
          this.order = result.data;
          this.cabinets = this.order.BOM.cabinets;

        });
    });

  }

  openUploadDialog(): void {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '400px',
      data: { _id: this.order._id, name: this.order.name }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result.success === true) {
        this.getOrder();
      }
    });
  }

  generateCuttingList() {
    this.orderService.getOptimizedCuttingListDiagram(this.order.BOM._id, "PDF");
  }

  delete() {
    this.orderService.delete(this.order._id, (res) => {
      this.router.navigate(['/orders']);
    } , () => {});
  }

  complete() {
    this.orderService.complete(this.order._id, (res) => {
      this.router.navigate(['/orders']);
    },
      () => { });
  }

  back() {
    this.router.navigate(['orders']);
  }

}

/**
 * Dialogue component
 */

@Component({
  selector: 'app-upload-dialogue',
  templateUrl: 'uploadDialog.html',
})
export class UploadDialogComponent {

  fileToUpload: File = null;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  submit() {
    this.loading = true;
    this.orderService.addBOMToOrder(this.data._id, this.fileToUpload, () => {
      this.loading = false;
      this.dialogRef.close({success: true});
    }, () => {
        this.loading = true;
        this.fileToUpload = null;
    });
  }

}
