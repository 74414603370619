import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Client } from '../../models/client';
import { Config } from '../../config/global';


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }



  createClient(client: Client, success: (r) => any, fail: (err) => any) {
    this.http.post<{data: any}>(Config.api.getBaseUrl() + '/clients', client)
      .subscribe((result: any) => {
        return success(result);
      }, (err: any) => {
        return fail(err);
      });
  }

  getAll() {
    return this.http.get<{data: any}>(Config.api.getBaseUrl() + '/clients');
  }

}
