import { OrderService } from './../../../services/orders/order.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-assembly',
  templateUrl: './assembly.component.html',
  styleUrls: ['./assembly.component.scss']
})
export class AssemblyComponent implements OnInit {

  sub: any;
  id: string;
  order: any;
  cabinets: any;

  constructor(private orderService: OrderService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getOrder();
  }

  /**
   * Gets order
   */
  getOrder() {
    // get id param
    this.sub = this.route.params.subscribe(params => {
      this.id = params.oid; // (+) converts string 'id' to a number

      this.orderService.getOrder(this.id)
        .subscribe(result => {
          this.order = result.data;
          this.cabinets = this.order.BOM.cabinets;
        });
    });

  }

}
