const ENV = require('./ENV');


const config = {
    api: {
        dev: {
            local: 'http://localhost:3000'
        },
        prod: 'https://api.apps.panelkraft.design',
        getBaseUrl: function() {
            switch (ENV) {
                case "PROD":
                    return this.prod;
                    break;
                case "DEV":
                    return this.dev.local;
                    break;
                default:
                    break;
            }
        }
    },
    msal: {
        redirect_prod: "https://pksys.apps.panelkraft.design/home",
        redirect_dev: "http://localhost:4200/home",
        getRedirectUrl: function() {
            switch (ENV) {
                case "PROD":
                    return this.redirect_prod;
                    break;
                case "DEV":
                    return this.redirect_dev;
                    break;
                default:
                    break;
            }
        }
    }
};

module.exports.Config = config;
