import { OrderService } from './../../services/orders/order.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  currentOrders: number = null;

  constructor(private orderService: OrderService) { }

  ngOnInit() {
    this.orderService.getAllOrders()
      .subscribe(orders => {
        this.currentOrders = orders.data.length;
      });
  }

}
