import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { MatCardModule, MatCard } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule, MatDatepicker } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Config } from './config/global';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';

import {
  MsalModule,
  MsalInterceptor,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
  MsalService,
  MsalAngularConfiguration } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { NavComponent } from './components/partials/nav/nav.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { LogoutComponent } from './pages/auth/logout/logout.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { Configuration } from 'msal';
import { OrderCardComponent } from './pages/orders/components/order-card/order-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderComponent, UploadDialogComponent } from './pages/order/order.component';
import { CreateComponent } from './pages/orders/subpages/create/create.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BomTreeComponent } from './pages/order/components/bom-tree/bom-tree.component';
import { AssemblyComponent } from './pages/manufacture/assembly/assembly.component';
import { CabinetCardComponent } from './pages/manufacture/assembly/components/cabinet-card/cabinet-card.component';
import { ClientsComponent } from './pages/clients/clients/clients.component';
import { NewClientComponent } from './pages/clients/new-client/new-client.component';
import { MatSelectModule } from '@angular/material/select';

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read']],
  ['http://localhost:3000/', ['api://c736ff21-0055-4790-bb2d-cb3b390200c8/access/ApiAccess']],
  ['https://api.apps.panelkraft.design:3000/', ['api://c736ff21-0055-4790-bb2d-cb3b390200c8/access/ApiAccess']]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: 'c736ff21-0055-4790-bb2d-cb3b390200c8',
      authority: "https://login.microsoftonline.com/420be65e-ff52-4a0c-8857-09fcae21d46f",
      validateAuthority: true,
      redirectUri: Config.msal.getRedirectUrl(),
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: false,
    consentScopes: [
      "user.read",
      "api://c736ff21-0055-4790-bb2d-cb3b390200c8/access/ApiAccess"
    ],
    unprotectedResources: ["https://www.microsoft.com/en-us/"],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    LoginComponent,
    LogoutComponent,
    OrdersComponent,
    OrderCardComponent,
    OrderComponent,
    CreateComponent,
    UploadDialogComponent,
    BomTreeComponent,
    AssemblyComponent,
    CabinetCardComponent,
    ClientsComponent,
    NewClientComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatMenuModule,
    MsalModule,
    NgxSkeletonLoaderModule,
    MatCardModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatTreeModule,
    CdkTreeModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    DragDropModule,
    MatExpansionModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory,
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UploadDialogComponent
  ]
})
export class AppModule { }
