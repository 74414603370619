import { ClientService } from './../../../services/clients/client.service';
import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  clients: Client[];

  columnsToDisplay = ['name', 'email', 'delete'];

  constructor(private clientService: ClientService) { }

  ngOnInit() {
    this.getClients();
  }

  getClients() {
    this.clientService.getAll()
      .subscribe(res => {
        this.clients = res.data;
      });
  }
}
