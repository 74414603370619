import { MsalService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

const graphMeEndpoint = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public profile: any;

  constructor(private http: HttpClient, private authService: MsalService, private router: Router) { }

  login() {
    this.authService.loginRedirect();
  }


  getProfile(success: (profile) => any) {
    this.http.get(graphMeEndpoint).toPromise()
      .then(profile => {
        this.profile = profile;
        return success(profile);
      });
  }
}
