import { UserService } from './services/user/user.service';
import { AppService } from './services/app.service';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Component, OnInit } from '@angular/core';
import { Logger, CryptoUtils } from 'msal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {

  public profile: any;

  isIframe = false;
  loggedIn = false;

  constructor(private broadcastService: BroadcastService, private authService: MsalService, private userService: UserService,
              private router: Router) {}


  async ngOnInit() {

    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
      this.router.navigate(['/home']);
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response.accessToken);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));


  }

  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
    if (!this.loggedIn) {
      this.router.navigate(['signin']);
    } else if (this.loggedIn) {

    }
  }




}
